.dashboard {
    display: flex;
    flex-direction: row;
}

/*.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.header-panel {
    width: 100%;
    height: 62px;
    background-color: grey;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 70vw;
    margin-top: -50px;
}*/

.logoff {
    width: 50px;
    align-self: flex-end;
    margin-bottom: 6px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-family: 'Roboto';
    cursor: pointer;
    color: white
}

.badgesSpace {
    display: flex;
    margin: 0;
}

.badge {
    border: 1px solid #e1e1e1;
    margin: 5px;
    border-radius: 9px;
    padding: 5px;
    font-size: 16px;
    width: 60px;
    text-align: center;
    background: #9B9B9B;
    cursor: pointer;
    color: #fff;
}

.badgeSelect {
    background: var(--primary-color);
}


.dashPadding p {
    font-size: 20px;
}

/* RESUMO DE CERTIFICAÇÔES */
.resumo {
    margin: 25px 0px;
}

.quarter-meta {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quarter-title {
    height: 75px;
    width: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    border-radius: 7px;
    text-align: center;
}

.quarter-title.current {
    background-color: rgba(150, 150, 150, 0.15);
    font-weight: 700 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quarter-text {
    color: #000;
    text-align: center;
    width: 100%;
    font-weight: 400;
    margin-bottom: 0
}

/* CARDS */
.cardSpace {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    width: 100%;
    flex-wrap: wrap;
}

.card-programacao-header {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.card_programacao {
    cursor: pointer;
    background: #c5c5c5;
    width: 150px;
    height: 150px;
    border: 1px solid transparent;
    border-radius: 5px;
    margin: 7px;
    padding: 13px 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

}



/* .info-icon-area {
    position: absolute;
    right: 0;
    margin-right: 5px;
} */

.info-icon {
    color: white;
    opacity: 1;

    &.grow {
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.5);
        }
    }
}

.card_programacao.concluido {
    background: #61ce78;
}

.logo {
    position: relative;
    height: 60px;
    max-height: 60px;
    max-width: 60px;
    margin: 0px;
    bottom: 0px;
    /* opacity: 0.8;
    mix-blend-mode:luminosity;
    filter: contrast(0.5) */
}

.resumo-cert-name {
    position: relative;
    z-index: 2;
    color: #ffffff;
    text-align: left;
    font-weight: 500;
    font-size: 14px !important;
    margin: -80px 0px 0px 0px;
    max-width: 90%;
}

/* MODAL */
.hoverInfo {
    position: absolute;
    display: none;
}

.ModalTitle {
    color: #000;
    font-size: 16px;
}

.modalInput {
    display: block;
    width: 80%;
    height: 45px;
    margin: 15px auto;
    border: solid 1px #eaeaea;
}

.btnArea {
    width: 95% !important;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* .btnSalvar {
    margin-top: 10px;
    display: block;
    float: right;
    background: var(--primary-color);
    padding: 10px;
    width: 100px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 9px;
} */

/* .brnRefresh {
    margin-top: 10px;
    display: block;
    float: left;
    background: #fff;
    padding: 10px;
    width: 100px;
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 9px;
} */


a {
    color: white;
    text-decoration: none;
    /* no underline */
}

.link-prova {
    color: blue;
    text-decoration: dashed;
    font-size: 14px;

}

.h2-info {
    font-size: 16px;
}

.user-message-space {
    height: 600px;
    background: #e9e9e9;
    border-radius: 10px;
    margin-top: 10px;
}

.padding-message-area {
    padding: 20%;
}

.dashboard-filters {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    max-width: 400px;
}

@media only screen and (max-width: 992px) {
    /* For mobile phones: */

    .page {
        max-width: 80vw;
        padding-left: 10px;
        padding-right: 10px;
    }

    /*.page-wrapper {
        width: 90% !important; 
        max-width: 80vw !important;
    }*/

    /* RESUMO DE CERTIFICAÇÔES */
    .quarter-meta {
        flex-direction: column;
    }

    .quarter-title {
        margin-right: 0px;
    }

    /* CARDS */
    .cardSpace {
        /*width: 100%;
        flex-wrap: wrap;*/
        justify-content: center;
    }

    .imgCard {
        align-self: center;
    }
}

@media only screen and (max-width: 576px) {
    /* For x-small: */

    /* CARDS */
    .cardSpace {
        align-items: stretch;
    }

    .card_programacao {
        width: 43%;
        height: auto;
        min-height: 150px;
    }

    .resumo-cert-name {
        width: 100%;
    }

    .imgCard {
        margin-top: auto;
    }


}