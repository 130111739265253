/* SKELETON */
.skeleton-grupo {
    height: 150px;
    width: 150px;
}

/* FILTROS */
.programacoes-filters {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 40px;
}

.programacoes-filters-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 40px;
}

/* DATA TABLE */
.MuiButton-startIcon {
    margin: 0px !important;
}

/* MODAL DE CADASTRO DE PROGRAMAÇÃO */
.cadastro-programacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 550px;
    padding: 25px;
}

.cadastro-programacao-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.cadastro-programacao-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.cert-opcao {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.delete-button{
    width: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-width: 992px) {
    /* For mobile phones: */
    
    .programacoes-filters-row {
        flex-direction: column;
    }
}

@media only screen and (max-width: 576px) {
    /* For x-small: */
    
}