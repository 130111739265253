.justify {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow:hidden
}

.welcome-banner {
    color: var(--contrast-text-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 4;
    background: linear-gradient(135deg, var(--primary-color), var(--primary-color-gradient-end));
}

.welcome-logo {
    max-height: 100%;
    max-width: 100%;
    margin-bottom: 15px;
}

.text-box {
    height: auto;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-size: 72px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
}

.subtitle {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.login-area {
    background: white;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.google_icon {
    fill: white;
    height: 40px;
    width: 40px;
}

@media only screen and (max-width: 992px) {
    /* For mobile phones: */
    .login {
        flex-direction: column;
        width: 100vw;
        max-height: 100vh;
    }

    .login-area {
        padding-bottom: 30px;
    }

    .welcome-banner {
        height: 70%;
    }

    .text-box {
        height: auto;
        min-width: 230px;
    }
}
