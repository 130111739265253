.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.header-panel {
    width: 100%;
    height: 62px;
    background-color: rgb(160, 160, 160);
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 70vw;
    margin-top: -50px;
}

@media only screen and (max-width: 992px) {
    /* For mobile phones: */

    .page-wrapper {
        width: 90% !important; 
        max-width: 80vw !important;
    }

    [class^="page"],
    [class*=" page"] {
        max-width: 80vw !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}