.filter-equipe {
    width: 300px;
}

.resumo-gestor-accordion-summary {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: start;
    align-items: center;
}

.summary-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* BADGE */
.badge_bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(4vh + 2vw + 25px );
    height: calc(4vh + 2vw + 25px);
}

.pie_white,
.pie_completion {
    position: absolute;
    border-radius: 50%;
}

.pie_white {
    position: absolute;
    background-color: white;
    width: 88%;
    height: 88%;
    z-index: 2;
}

.pie_completion {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pie_logo {
    z-index: 3;
    max-width: 47%;
    max-height: 47%;
}

/* CARD SUMMARY TEXT */
.summary-text {
    margin-left: 25px;
    width: 75%;
}

.cardKPI {
    width: 75px;
}

.summary-text-lower {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: start;
    gap: 35px;
}

/* ACCORDION CONTENT */
.accordion-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.coluna-usuarios {
    display: flex;
    flex-direction: column
}

.MuiAccordion-root {
    margin-bottom: 10px !important;
    border: solid 1px #E8E8E8 !important;
    background-color: #E8E8E8 !important;
    /*color: #000 !important;*/
}

.selector-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
}


/* RESPONSIVENESS */
@media (max-width: 992px) {
    /* For mobile phones: */

    .summary-details {
        display: none;
    }

}

@media (max-width: 576px) {

    /* For x-small: */
    .summary-details {
        display: none
    }
}