.sidebar {
    width: 16vw;
    max-width: 220px ;
    min-width: 180px;
    height: 100vh;
    background: var(--primary-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 1001;
}

.sidebar a {
    color: var(--contrast-text-color);
}

.ic {
    height: 40px;
    margin: 35px 0px;
    cursor: pointer;
    max-width: 95%;
}

.user {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.lines {
    width: 75%;
    height: 125px;
    box-shadow: 0 1px 0 var(--contrast-lines-color) inset, 0 -1px 0 var(--contrast-lines-color) inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userAvatar {
    font-size: 50px;
    cursor: pointer;
    margin: 0px;
    color: var(--contrast-text-color)
}

.avatar-picture {
    border-radius: 50px;
    width: 55px;
    margin: 0 auto;
    display: block;
    font-size: 10px;
}

.user-name {
    font-size: 12px;
    margin-bottom: 0px;
}

.menuButton {
    display: none;
    height: 40px;
    font-size: 50px;
    cursor: pointer;
    color: var(--contrast-text-color)
}

.MenuList {
    margin-top: 20px;
    color: var(--contrast-text-color)
}

.userType {
    font-weight: 600;
    margin-bottom: 10px;
}

.ul {
    margin-left: -5px;
    padding: 0;
}

li {
    list-style: none;
}

.menulink {
    cursor: pointer;
    margin-bottom: 10px;
}

.last {
    margin-bottom: 25%;
}

.exit {
    position: absolute;
    bottom: 0;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
}

.sair-p {
    margin: 0 5px;
}

@media only screen and (max-width: 992px) {
    /* For mobile phones: */

    .sidebar {
        height: 100vh;
        position: sticky;
        top: 0px;
        left: 0px;
        margin-left: -125px;
        transition: all 0.1s ease;
    }

    .sidebarColapse {
        width: 10vw;
        max-width: 70px ;
        min-width: 40px ;
        margin-left: 0px;
    }

    .sidebarColapse .avatar-picture {
        max-width: 30px;
    }

    .sidebarColapse .user-name {
        display: none;
    }

    .menuButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 15px 0px;
        transition: all 0.1s ease;
    }

    .sidebarColapse .menuButton {
        height: 100%;
    }

    .MenuList {
        overflow-y: auto;
        max-height: 100%;
        margin-top: 0px;
        margin-bottom: 45px;
        opacity: 1;
        transition: all 2s ease;
        transition-delay: 0.1s;
    }

    .MenuList ul {
        margin-left: 0px;
    }

    .sidebarColapse .MenuList {
        display: none;
        opacity: 0;
    }
}

@media only screen and (max-width: 576px) {

    /* For x-small: */
    .sidebar {
        margin-left: -140px;
    }

    .sidebarColapse {
        margin-left: 0px;
        width: 10vw;
        width: 50px !important;
    }

}