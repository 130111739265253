.cronograma {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cronograma-filters {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    column-gap: 25px
}

.quarter {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.quarter-title {
    margin: 0px 0px;
}

.grupos {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(auto-fill);
    /* justify-content: space-between; */
    justify-content: center;
    width: 95%;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    margin-bottom: 25px;
}

.grupo {
    display: flex;
    flex-direction: column;
    padding: 5px;
    /*max-width: 300px;*/
    background-color: rgb(208, 208, 208);
}

.skeleton-grupo {
    height: 150px;
    width: 150px;
}

.grupo-title {
    font-weight: 700;
}

@media only screen and (max-width: 992px) {

    /* For mobile phones: */
    .grupos {
        grid-template-columns: repeat(auto-fill, 45%) !important;
        width: 97%;
    }

    .grupo {
        width: auto !important;
    }
}

@media only screen and (max-width: 576px) {

    /* For x-small: */
    .grupos {
        grid-template-columns: repeat(auto-fill, 100%) !important;
    }

    .grupo {
        width: auto !important;
    }
}