:root {
  --jira-modal-display: none;
}

#atlwdg-trigger {
  display: var(--jira-modal-display, none);
  z-index: 2;
  font-size: 12px;
  border-radius: 7px 0 0 0;
  background-color: var(--primary-color) !important;
  color: var(--contrast-text-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 992px) {
  /* For mobile phones: */

  #atlwdg-container {
    max-width: 90vw;
  }
}