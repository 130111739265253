.page{
    overflow-anchor: none;
}

.card-skeleton {
    width: 100%;
    height: auto;
    border: 1px solid #bebebe;
    border-radius: 5px;
    margin: 7px;
    padding: 13px 10px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.name-skeleton{
    color: #bebebe;
}

.filters-skeleton{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
}

.table-skeleton{
    width: 80%;
}

.bg {
    background-color: lightgrey;
    height: 5700px;
}

.filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.table {
    width: 100%;
}

.selects {
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px 0px;
    gap: 5px;
    flex-grow: 12;
}

.select-placeholder{
    margin-right: 5px;
}

.select-container{
    display:flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    flex-grow: 5;
    margin-left: 50px;
    padding: 40px 0px;
    font-size: 20px;
}

.circular-progress {
    margin-top: 15px;
}

@media only screen and (max-width: 992px) {
    /* For mobile phones: */
    
    .filters {
        flex-direction: column;
        justify-content: center;
    }

    .info {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 576px) {
    /* For x-small: */
    
}