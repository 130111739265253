/* MODAL DE CADASTRO */
.modal-body-contents{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 600px;
    row-gap: 15px;
    padding: 20px 15px;
}

.modal-cadastro-filters{
    display:flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
}

.text-modal-cert{
    padding: 5px !important; 
}

.cadastrar-certificacao {
    width: 25%;
    height: 30px;
    margin: 5px;
    background: #5CCF67;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.page-title{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
}

.add-cert-title {
    display: flex;
}

.add-icon-cert {
    font-size: 25px;
    margin-top: 12px;
    margin-right: 5px;
    cursor: pointer;
}


.btn-delete-cert{
    width: 25%;
    height: 30px;
    margin: 5px;
    background: #5CCF67;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.message-delete {
    text-align: center;
    font-weight: 700;
}

.certificacoes-select{
    padding: 5px;
}

.select-empresa {
    margin: 5px;
}

.select-grupo{
    margin: 5px;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.css-50hcjr-MuiButtonBase-root-MuiMenuItem-root {
    background: #fff;
}