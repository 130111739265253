.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1010;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 500px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  border-radius: 5px;

}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: none;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 10px;
  /* border-top: 1px solid #eee; */
}