.gestao-equipes {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}


/* FILTROS */
.gestao-equipe-filters {
    display: flex;
    flex-direction: column;
    column-gap: 25px;
    row-gap: 20px;
    justify-content: center;
    max-width: 600px;
}

.add-colab-section {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.pageEquipe {
    background-color: white;
    border-radius: 7px;
    padding: 50px;
    /*width: 100%;*/
    height: 100%;
}

.dashboard {
    display: flex;
    flex-direction: row;
}

.headerOptions {
    color: #000;
    font-weight: 600;
    margin-top: 5%;
    padding: 5px;
    margin-bottom: 10px !important;
}

.badgesSpace {
    display: flex;
    margin-top: 0%;
}

.badgeTime {
    border: 1px solid #e1e1e1;
    width: 105px;
    padding: 20px;
    margin-left: 5px;
    border-radius: 9px;
    text-align: center;
    background: #9B9B9B;
    color: #fff;
    cursor: pointer;
}

.clear-filtro {
    width: 200px;
    width: 200px;
    margin-top: 10px;
    color: #9b9b9b;
    background: transparent;
    border: transparent;
    text-decoration: underline;
    margin-left: 0px;
    cursor: pointer;
}

.badgeSelect {
    background: var(--primary-color);
}

.equipe-select {
    width: 225px;
}

.editVisibleTeam {
    display: flex;
    margin-top: 60px;
    margin-bottom: 20px;
}

.editVisibleTeam.colab-input {
    margin-top: 15px;
}

.changeInactivesText {
    font-size: 16px;
}

.btn-add {
    margin-left: 10px;
    width: 50px;
    background: #5CCF67;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.new-colab-input {
    max-width: 380px;
    width: 100%;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge.css-tzssek-MuiSvgIcon-root {
    margin-top: 10px;
    margin-right: 15px;
} */

.vOn {
    margin-top: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.vOff {
    margin-top: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.addIcon {
    margin-top: 10px;
    margin-right: 10px;
}

.actionIcon {
    cursor: pointer;
}

.toggleIcon {
    cursor: pointer;
}

.dash-colab {
    /*margin-left: 12%;*/
    width: 100%;
    display: flex;
    flex-direction: column;
}

.back-colab {
    position: absolute;
    top: auto;
    cursor: pointer;
}

.MuiModal-root.css-79ws1d-MuiModal-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff !important;
    border: solid 1px #000;
    border-radius: 9px;
    width: 25%;
    height: max-content;
}

.MuiBackdrop-root.MuiModal-backdrop.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background: #fff;
}

.selec-area {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.select-modal.css-l5ked3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 30%;
    margin-right: 10px;
}

.title-area {
    display: flex;
    justify-content: center;
}

.remove-modal{
    height: 225;
}
.modal-title {
    margin: 0;
    text-align: center;
    margin-top: 5px;
}

.message-modal {
    text-align: center;
    margin: 20px;
    margin-top: 50px;
    margin-bottom: 75px;
}
.btn-remove-area{
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-right: 35px;

}

.btn-modal-action {
    margin-top: 10px;
    display: block;
    float: left;
    background: var(--primary-color);
    padding: 10px;
    width: 100px;
    text-align: center;
    color: var(--contrast-text-color);
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 9px;
}

.btn-modal-action-invert {
    margin-top: 10px;
    display: block;
    float: left;
    background: var(--contrast-text-color);
    padding: 10px;
    width: 100px;
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 9px;
}


svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.close-modal.css-i4bv87-MuiSvgIcon-root {
    position: absolute;
    right: 0;
    margin-right: 7px;
    cursor: pointer;
}

.select-cert-area {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.select-modal-cert.css-l5ked3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 60%;
}

.input-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
}

.MuiFormControl-root.MuiTextField-root.input-size-modal.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 60%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.select-equipe {
    width: 25%;
    min-width: 200px;
}

@media only screen and (max-width: 992px) {

    /* For mobile phones: */
    .badgesSpace {
        flex-wrap: wrap;
    }

    .badgeTime {
        max-width: 28%;
        width: auto;
    }

    .MuiModal-root.css-79ws1d-MuiModal-root {
        width: 50%;
    }
}

@media only screen and (max-width: 572px) {

    /* For x-small: */
    .MuiModal-root.css-79ws1d-MuiModal-root {
        width: 75%;
    }

    .equipe-select {
        width: 100%;
    }

    .select-equipe {
        width: 100%;
    }

    .editVisibleTeam {
        margin-top: 30px;
    }

    .colab-input {
        flex-direction: column;
    }

    .new-colab-input {
        width: 100%;
    }

    .btn-add {
        width: 50%;
        height: 50px;
        align-self: center;
        margin-top: 10px;
    }
}